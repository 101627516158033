import React from 'react';
import { useNavigate } from 'react-router-dom';
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PaidIcon from "@mui/icons-material/Paid";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import Tap from "@mui/icons-material/Home";
import { Box, Button } from "@mui/material";

const BottomNavBar = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#F4FDFF0A',
        color: 'white',
        borderRadius: '12px 12px 12px 12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        zIndex: 1000,
        boxSizing: 'border-box',
        '@media (max-width: 600px)': {
          padding: '5px',  // Reduce padding on smaller screens
        },
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate('/friends')}
        sx={{
          flexGrow: 1,
          flexBasis: '20%',  // Distribute evenly across 5 buttons
          maxWidth: '60px',  // Control maximum width
          minWidth: '50px',
          height: 'auto',  // Let height adjust naturally
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: '10px',
          lineHeight: '14px',
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid transparent',
          padding: '5px',
          margin: '0 2px',
          overflow: 'hidden',
          gap: '4px',
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
          '@media (max-width: 400px)': {
            fontSize: '8px',  // Adjust font size for small screens
            padding: '4px',   // Adjust padding
            margin: '0 1px',
          },
        }}
      >
        Friends <GroupAddIcon />
      </Button>

      <Button
        variant="contained"
        onClick={() => navigate('/skin')}
        sx={{
          flexGrow: 1,
          flexBasis: '20%',
          maxWidth: '60px',
          minWidth: '50px',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: '10px',
          lineHeight: '14px',
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid transparent',
          padding: '5px',
          margin: '0 2px',
          overflow: 'hidden',
          gap: '4px',
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
          '@media (max-width: 400px)': {
            fontSize: '8px',
            padding: '4px',
            margin: '0 1px',
          },
        }}
      >
        Skins <BurstModeIcon />
      </Button>

      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          flexGrow: 1,
          flexBasis: '20%',
          maxWidth: '60px',
          minWidth: '50px',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: '10px',
          lineHeight: '14px',
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid transparent',
          padding: '5px',
          margin: '0 2px',
          overflow: 'hidden',
          gap: '4px',
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
          '@media (max-width: 400px)': {
            fontSize: '8px',
            padding: '4px',
            margin: '0 1px',
          },
        }}
      >
        Tap <Tap />
      </Button>

      <Button
        variant="contained"
        onClick={() => navigate('/tasks')}
        sx={{
          flexGrow: 1,
          flexBasis: '20%',
          maxWidth: '60px',
          minWidth: '50px',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: '10px',
          lineHeight: '14px',
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid transparent',
          padding: '5px',
          margin: '0 2px',
          overflow: 'hidden',
          gap: '4px',
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
          '@media (max-width: 400px)': {
            fontSize: '8px',
            padding: '4px',
            margin: '0 1px',
          },
        }}
      >
        Tasks <AssignmentTurnedInIcon />
      </Button>

      <Button
        variant="contained"
        onClick={() => navigate('/airdrop')}
        sx={{
          flexGrow: 1,
          flexBasis: '20%',
          maxWidth: '60px',
          minWidth: '50px',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: '10px',
          lineHeight: '14px',
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid transparent',
          padding: '5px',
          margin: '0 2px',
          overflow: 'hidden',
          gap: '4px',
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
          '@media (max-width: 400px)': {
            fontSize: '8px',
            padding: '4px',
            margin: '0 1px',
          },
        }}
      >
        Airdrop <PaidIcon />
      </Button>
    </Box>
  );
};

export default BottomNavBar;
