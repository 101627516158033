import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Backdrop, Fade, Typography, Grid, Box, Button, CircularProgress } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import totalpointsIcon from '../images/EarnCoin.png';
import taskEx from '../images/taskEx.svg';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import { message } from 'antd';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
}));

const TaskModal = ({ open, handleClose, userData, task }) => {
  const [tasks, setTasks] = useState([]);
  const [taskLoading, setTaskLoading] = useState(true); // Separate loading state for tasks
  const [completedTasks, setCompletedTasks] = useState([]);
  const [isUrlOpened, setIsUrlOpened] = useState([]);
  const [showLoader, setShowLoader] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/tasks/${userData.id}`,
      );
      const data = response.data;
      setTasks(data.tasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setTaskLoading(false); // Hide task loading after fetching
    }
  }, [userData.id]);

  const completeTask = async (id) => {
    const data = { taskId: id };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/complete-task/${userData.id}`,
        data,
      );

      if (response.status === 200 || response.status === 201) {
        setShowLoader(false);
        setCompletedTasks((prevCompletedTasks) => [...prevCompletedTasks, id]);
        message.success("Task completed successfully");
      }
    } catch (error) {
      console.error("Error completing the task:", error);
      setShowLoader(false);
    }
  };

  const handleClaimButtonClick = (index, task) => {
    if (!isUrlOpened[index]) {
      if (task.socialUrl) {
        window.open(task.socialUrl, '_blank');
      }
      const updatedIsUrlOpened = [...isUrlOpened];
      updatedIsUrlOpened[index] = true;
      setIsUrlOpened(updatedIsUrlOpened);
    } else {
      setShowLoader((prevState) => ({ ...prevState, [task._id]: true }));
      completeTask(task._id);
      const updatedIsUrlOpened = [...isUrlOpened];
      updatedIsUrlOpened[index] = false;
      setIsUrlOpened(updatedIsUrlOpened);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div
      style={{
        height: '85vh',
        paddingBottom: '130px',
        boxSizing: 'border-box',
        overflowY: 'scroll',
        padding: '25px',
      }}
    >
      {/* Heading and Image */}
      <Grid container spacing={4} textAlign={'center'}>
        <Box sx={{ textAlign: 'center', width: '100%', ml: 2, mb: 6, mt: 5 }}>
          <img src={totalpointsIcon} width={100} height={100} alt='EarnCoin' sx={{
            filter: 'drop-shadow(2.5px 2.5px 5px gold)',
          }} />
          <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'2rem'} sx={{ mt: 4 }}>
            Tasks to earn more
          </Typography>
        </Box>
      </Grid>

      {/* Task List Label */}
      <Grid container spacing={4} textAlign={'center'}>
        <Box sx={{ alignItems: 'center', width: '100%', ml: 2,mb:4 }}>
          <Box style={{ textAlign: 'center', width: '95%', margin: '5px', padding: '10px', borderRadius: '10px' }}>
            <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1.5rem'}>
              <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'} background={'transparent'}>
                Task list
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* Task List Loading Spinner or Tasks */}
      {taskLoading ? (
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start', // Align the spinner towards the top
          marginTop: '30px', // Adjust this value to move the spinner down or up
          height: '100%',
        }}
      >
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
      ) : (
        <Grid container spacing={4} textAlign={'center'}>
          <Box sx={{ alignItems: 'center', width: '100%', ml: 2 }}>
            {tasks.map((taskData, index) => (
              <Box
                bgcolor={'#F4FDFF0A'}
                color={'white'}
                style={{ textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '30px', alignItems: 'center' }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
                key={index}
              >
                <img src={taskData.taskLogoUrl} width={50} height={50} style={{ marginRight: '10px' }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
                  <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} fontSize={'1rem'}>
                    {taskData.taskName}
                  </Typography>
                  <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={500} fontSize={'0.7rem'}>
                    {taskData.description}
                  </Typography>
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={totalpointsIcon} width={20} height={20} style={{ marginRight: '5px' }} /> +{taskData.taskPoints}
                  </Typography>
                </Box>
                <Button
                  onClick={() => handleClaimButtonClick(index, taskData)}
                  disabled={completedTasks.includes(taskData._id)}
                  sx={{
                    color: "#000000",
                    backgroundColor: isUrlOpened[index] ? "#A6FF00" : "transparent",
                    borderRadius: "10%",
                    marginLeft: '10px',
                    '&:hover': {
                      backgroundColor: isUrlOpened[index] ? "#A6FF00" : "transparent",
                    },
                  }}
                >
                  {taskData.completed ? (
                    <CheckIcon
                      sx={{
                        alignItems: 'center',
                        background: 'linear-gradient(180deg, #74fc82, #297e32)',
                        borderRadius: '50%',
                        display: 'flex',
                        height: '26px',
                        justifyContent: 'center',
                        width: '26px',
                        color: "white"
                      }}
                    />
                  ) : isUrlOpened[index] ? (
                    "Claim"
                  ) : (
                    <img src={taskEx} width={30} height={30} sx={{ backgroundColor: "transparent" }} alt="Task Example" />
                  )}
                </Button>
              </Box>
            ))}
          </Box>
        </Grid>
      )}
    </div>
  );
};

export default TaskModal;
