import React, { useState } from 'react';
import { Button, Typography, Grid, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { message } from 'antd';
import profileImage from '../images/profile.png';
import earnCoinImage from '../images/EarnCoin.png';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ theme, open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
}));

const LeaderboardModal = ({ open, handleClose, userData, leaderboardList }) => {
  const [activeTab, setActiveTab] = useState('leaderboard'); // State to track which tab is active
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle tab navigation
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'friends') {
      navigate('/friends'); // Navigate to the friends route
    }
  };

  return (
    <div style={{
      height: '85vh',
      paddingBottom: '130px', // Adjust this value to match your navbar height
      boxSizing: 'border-box',
      overflowY: 'scroll',
      padding: '30px',
    }} >
      {/* Toggle buttons */}
      <Box sx={{ textAlign: 'center', mb: 4,borderRadius:'20px',display:'flex', justifyContent:'center',height:'5vh' }}>
      <Box sx={{border:'1px solid black', width:'69vw', borderRadius:'20px', backgroundColor:'black',height:'5vh'}}>
        <Button
          variant={activeTab === 'friends' ? 'contained' : 'outlined'}
          sx={{
            mr: 0, // Remove margin between buttons
            width: '33.5vw',
            height:'5vh',
            borderRadius: '20px 0 0 20px', // Rounded on the left side
            backgroundColor: activeTab === 'friends' ? 'blue' : 'black',
            color: 'white',
            border: '1px solid',
            fontSize: '4vw', // Responsive font size based on viewport width
            borderColor: activeTab === 'friends' ? 'blue' : 'black',
            '&:hover':{
              border:"none"
            }
          }}
          onClick={() => handleTabChange('friends')}
        >
          Friends
        </Button>
        <Button
          variant={activeTab === 'leaderboard' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange('leaderboard')}
          sx={{
            width: '35.5vw',
            height:'5vh',
            borderRadius: '20px', // Rounded on the right side
            backgroundColor: activeTab === 'leaderboard' ? 'blue' : 'black',
            color: 'white',
            border: '1px solid',
            fontSize: '4vw', // Responsive font size based on viewport width
            borderColor: activeTab === 'leaderboard' ? 'blue' : 'black',
          }}
        >
          Leaderboard
        </Button>
        </Box>
      </Box>

      {/* Conditionally render content */}
      {activeTab === 'leaderboard' && (
      <>
      <Grid container spacing={4} textAlign={'center'}>
        <Box sx={{ textAlign: 'center', width: '100%', ml: 2, mb: 6 }}>
          <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'2rem'} sx={{ mt: 4 }}>Leaderboard </Typography>
          <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'} sx={{ mt: 0.5 }}>Here is top 10 Players </Typography>
        </Box>
      </Grid>
      <Grid container spacing={4} textAlign={'center'}>
        <Box sx={{ alignItems: 'center', width: '100%', ml: 2 }}>
          {leaderboardList?.map((referral, index) => (
            
            <Box key={index} style={{ textAlign: 'left', width: '95%', margin: '5px', padding: '5px 10px', borderRadius: '10px', alignItems: 'center', display: 'flex', gap: '10px' }}>
              {/* Profile Image */}
              {console.log(`User: ${referral.username || "Anonymous"}, Points: ${referral.points}`)}
              <img
                src={referral.profile ? referral.profile : profileImage}
                alt="Profile"
                style={{ width: '30px', height: '30px', borderRadius: '50%' }}
              />
              <Box style={{ flex: 1 }}>
                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'} display={"flex"} justifyContent="space-between" alignItems="center">
                  {referral.username || "Anonymous"}
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={earnCoinImage}
                      alt="Dollar"
                      style={{ width: '20px', height: '20px', marginRight: '5px' }}
                    />
                    <span style={{ color: 'white', fontSize: '1rem' }}>
                      {referral.points}
                    </span>
                  </Box>
                </Typography>
              </Box>
            </Box>
          ))}
          {leaderboardList.length === 0 && (
            <Box bgcolor={'#ffffff7d'} style={{ textAlign: 'left', width: '95%', margin: '5px', padding: '10px', borderRadius: '10px' }}>
              <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} fontSize={'1.5rem'}>Ranking is empty :(</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </>
     
      )}
    </div>
  );
};

export default LeaderboardModal;
