import React, { useEffect, useRef } from 'react';

const TranslateComponent = () => {
  const googleTranslateRef = useRef(null);

  useEffect(() => {
    let intervaild;

    const checkGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        clearInterval(intervaild);
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            includedLanguages: 'en,fr,zh-CN,ar,tr,es,de,ru,pt,ur,hi,mr,ja,bn,vi,id,zh' // specify the languages you want
          },
          googleTranslateRef.current
        );
      }
    };

    intervaild = setInterval(checkGoogleTranslate, 100);

    return () => clearInterval(intervaild);
  }, []);

  return <div ref={googleTranslateRef}></div>;
};

export default TranslateComponent;
