import { useState, useEffect } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";

// Import your components and images
import CoinApp from "./components/CoinApp";
import FreindsModal from "./components/Freinds";
import LeaderboardModal from "./components/Leaderboard";
import TaskModal from "./components/Task";
import AirdropModal from "./components/Airdrop";
import SkinsModal from "./components/Skins";
import BoostModal from "./components/Boost";
import BottomNavbar from "./components/BottomNavbar";
import GoogleTrans from "./components/GoogleTranslator/Translator";
import BotQR from './images/botQR.png';
import OrangeCoin from "./images/SilverChar.png";
import defaultCoin from "./images/basicChar.png";
import GuardCoin from "./images/goldchar.png";
import BattleCoin from "./images/Diamand.png";
import "./App.css";
import "./css/customStyle.css";
import './index.css';

const theme = createTheme();
const telApp = window.Telegram.WebApp;

function App() {
  const [openSkins, setOpenSkins] = useState(false);
  const [openFriends, setOpenFriends] = useState(false);
  const [task, setTask] = useState([]);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [userCurrentRank, setUserCurrentRank] = useState(null);
  const [userCurrentSkinImage, setUserCurrentSkinImage] = useState(0);
  const [userCurrentSkinID, setUserCurrentSkinID] = useState();
  const [userSkins, setUserSkins] = useState([]);
  const [userCurrentReferrals, setUserCurrentReferrals] = useState(0);
  const [userReferralsInfo, setUserReferralsInfo] = useState([]);
  const [openTaskList, setOpenTaskList] = useState(false);
  const [userData, setUserData] = useState([]);
  const [profileUrl, setProfileUrl] = useState(null);
  const [pointCount, setPointCount] = useState(0);
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(true);
  const [miningInfo, setMiningInfo] = useState({
    status: "idle",
    perClick: 50,
    limit: 2000,
    max: 2000,
  });
  const [isSignedUp, setIsSignedUp] = useState(false);

  useEffect(() => {
    if (telApp) {
      telApp.ready();
      init();
      // Request full screen mode
      telApp.expand();
    }
  }, []);

  useEffect(() => {
    if (userData.id) {
      handleMiningInfo();
      if (!isSignedUp) {
        handleSignUp();
      }
    }
  }, [userData]);

  const init = () => {
    const search = window.Telegram.WebApp.initData;
    let data = null;
    if (search) {
      const converted = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      data = JSON.parse(converted.user);
    }
    if (data) {
      setUserData(data);
      setIsTelegramMiniApp(true);
    } else {
      setIsTelegramMiniApp(false);
    }
  };

  const handleMiningInfo = () => {
    if (typeof userData.id === "undefined") return null;
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/${userData.id}`)
      .then((response) => {
        if (response.data && "points" in response.data) {
          setPointCount(response.data.points);
        }
        if (response.data && "limit" in response.data) {
          setMiningInfo((prevMiningInfo) => {
            return { ...prevMiningInfo, limit: response.data.limit };
          });
        }
      })
      .catch((error) => console.error("Mining error:", error));
  };

  const handleSignUp = () => {
    if (typeof userData.id === "undefined") return;
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/signup`, {
        userId: userData.id,
        username: userData.username,
        firstname: userData.first_name,
        lastname: userData.last_name ? userData.last_name : "null",
      })
      .then((response) => {
        setIsSignedUp(true);
      })
      .catch((error) => console.error("Signup error:", error));
  };

  useEffect(() => {
    const req = async () => {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/${userData.id}`
        );
        const userCurrentSkinID = userResponse.data.skinID;
        setUserSkins(userResponse.data.skins);
        setUserCurrentSkinID(userCurrentSkinID);
        setUserCurrentReferrals(userResponse.data.referrals);
        setUserReferralsInfo(userResponse.data.referralsInfo);

        switch (userCurrentSkinID) {
          case 1:
            setUserCurrentSkinImage(defaultCoin);
            break;
          case 2:
            setUserCurrentSkinImage(OrangeCoin);
            break;
          case 3:
            setUserCurrentSkinImage(GuardCoin);
            break;
          case 4:
            setUserCurrentSkinImage(BattleCoin);
            break;
          default:
            setUserCurrentSkinImage(defaultCoin);
        }
      } catch (error) {
        console.error("Error getting user data:", error);
      }

      try {
        const rankResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/${userData.id}/get-rank`
        );
        setUserCurrentRank(rankResponse.data.rank);
      } catch (error) {
        console.error("Error getting rank:", error);
      }

      try {
        const leaderboardResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/leaderboard`
        );
        setLeaderboardList(leaderboardResponse.data.users);
      } catch (error) {
        console.error("Error getting leaderboard:", error);
      }
    };

    req();
  }, [openSkins, openFriends, openTaskList]);

  return (
    <div className="App">
      <GoogleTrans />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<CoinApp userData={userData}
                                                    telApp={telApp}
                                                    userId={userData.id}
                                                    pointCount={pointCount}
                                                    setPointCount={setPointCount}
                                                    miningInfo={miningInfo}
                                                    setMiningInfo={setMiningInfo} />} />

            <Route path="/friends" element={<FreindsModal userData={userData} open={true}
                                                          referralCount={userCurrentReferrals}
                                                          referralList={userReferralsInfo === "null" ? [] : userReferralsInfo} />} />

            <Route path="/leaderboard" element={<LeaderboardModal userData={userData}
                                                                 open={true}
                                                                 leaderboardList={leaderboardList} />} />

            <Route path="/skin" element={<SkinsModal open={true}
                                                     handleClose={() => setOpenSkins(false)}
                                                     userData={userData}
                                                     userSkins={userSkins}
                                                     userCurrentSkin={userCurrentSkinID} />} />

            <Route path="/tasks" element={<TaskModal
                                             userData={userData}
                                             open={true}
                                             handleClose={() => setOpenTaskList(false)} />} />

            <Route path="/airdrop" element={<AirdropModal open={true}
                                                          userData={userData} />} />

            <Route path="/boost" element={<BoostModal userData={userData} open={true} />} />
          </Routes>
          <BottomNavbar />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
