import React, { useState } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const FriendsModal = ({ open, handleClose, userData, referralCount, referralList }) => {
  const [activeTab, setActiveTab] = useState('friends'); // State to track which tab is active
  const navigate = useNavigate(); // Hook for navigation

  const handleReferral = () => {
    const referralLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userData.id}`;
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        message.success("Copied to clipboard!");
      })
      .catch((error) => {
        message.error("Failed to copy to clipboard. Please try again.");
      });
  };

  const glowStyle = {
    fontFamily: "avenir, sans-serif, serif",
    fontWeight: 700,
    color: "white",
    fontSize: "7.5rem",
    textShadow: "0 0 5px #A6FF00",
  };

  const goToLeaderboard = () => {
    navigate('/leaderboard'); // Navigate to the leaderboard route
  };

  return (
    <div style={{
      height: '85vh',
      paddingBottom: '130px',
      boxSizing: 'border-box',
      overflowY: 'scroll',
      padding: '30px',
    }}>
      {/* Toggle buttons */}
      <Box sx={{ textAlign: 'center', mb: 4, display: 'flex', justifyContent: 'center', height: '5vh' }}>
        <Box sx={{ border: '1px solid black', width: '69vw', borderRadius: '20px', backgroundColor: 'black', height: '5vh' }}>
          <Button
            variant={activeTab === 'friends' ? 'contained' : 'outlined'}
            sx={{
              mr: 0, // Remove margin between buttons
              width: '35vw',
              height: '5vh',
              borderRadius: '20px', // Rounded corners: left side only
              backgroundColor: activeTab === 'friends' ? 'blue' : 'black',
              color: 'white',
              fontSize: '4vw', // Responsive font size based on viewport width
            }}
            onClick={() => setActiveTab('friends')}
          >
            Friends
          </Button>
          <Button
            variant={activeTab === 'leaderboard' ? 'contained' : 'outlined'}
            onClick={goToLeaderboard}
            sx={{
              width: '34vw',
              backgroundColor: activeTab === 'leaderboard' ? 'blue' : 'black',
              color: 'white',
              height: '5vh',
              border: '1px solid black',
              borderRadius: '20px',
              fontSize: '4vw', // Responsive font size based on viewport width
              '&:hover': {
                border: 'none'
              }
            }}
          >
            Leaderboard
          </Button>
        </Box>
      </Box>

      {/* Conditionally render content */}
      {activeTab === 'friends' ? (
        <Grid container spacing={2} textAlign={"center"}>
          {/* Reduced the margin and padding here */}
          <Box sx={{ textAlign: "center", width: "100%", ml: 2 }}>
            <Typography fontFamily={"avenir, sans-serif, serif"} fontWeight={700} color={"white"} fontSize={"1.5rem"} sx={{ mt: 2 }}>
              You invited:
            </Typography>
            <Typography style={glowStyle}>{referralCount}</Typography>
          </Box>
          {/* Reduced the padding and margin in this section */}
          <Box
            sx={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: 'auto',
              padding: '20px', // Reduced padding
            }}
          >
            <Typography fontFamily={"avenir, sans-serif, serif"} color={"white"} fontWeight={700} fontSize={"1.5rem"}>
              Referral link:
            </Typography>
            <Typography fontFamily={"avenir, sans-serif, serif"} fontWeight={700} fontSize={"1rem"} width="95%" sx={{ p: 1, backgroundColor: "#F4FDFF0A", color: "white", borderRadius: "10px" }}>
              {`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userData.id}`}
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: "10px",
                backgroundColor: "#A6FF00",
                color: "black",
                mt: 1.5, // Reduced margin-top
                "&:hover": { backgroundColor: "#A6FF00" },
              }}
              onClick={handleReferral}
            >
              Copy Link <ContentCopyIcon fontSize="small" sx={{ ml: 1 }} />
            </Button>
          </Box>
          <Box sx={{ alignItems: "center", width: "100%", ml: 2 }}>
            <Typography fontFamily={"avenir, sans-serif, serif"} fontWeight={700} color={"white"} fontSize={"1.5rem"} sx={{ mt: 4 }}>
              <img style={{ verticalAlign: "middle" }} width="30" height="30" src="https://img.icons8.com/color/48/friends--v1.png" alt="friends-icon" />
              Your friends:
            </Typography>
            {referralList?.map((referral, index) => (
              <Box key={index} bgcolor={"#F4FDFF0A"} style={{ textAlign: "left", width: "95%", margin: "5px", padding: "10px", borderRadius: "10px" }}>
                <Typography fontFamily={"avenir, sans-serif, serif"} color={"white"} fontWeight={700} fontSize={"1.5rem"}>
                  {referral.firstname} {referral.lastname}
                </Typography>
                <Typography fontFamily={"avenir, sans-serif, serif"} color={"white"} fontWeight={700} fontSize={"1rem"}>
                  ID: {referral.userId}
                </Typography>
              </Box>
            ))}
            {referralList.length === 0 && (
              <Box bgcolor={"#F4FDFF0A"} style={{ textAlign: "center", width: "95%", margin: "5px", padding: "10px", borderRadius: "10px" }}>
                <Typography fontFamily={"avenir, sans-serif, serif"} fontWeight={700} color={"white"} fontSize={"1.5rem"}>
                  No friends :
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      ) : null}
    </div>
  );
};

export default FriendsModal;
