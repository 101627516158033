import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import axios from 'axios';
import BattleCoin from '../images/Diamand.png';
import purpleCoin from '../images/basicChar.png';
import GuardCoin from '../images/goldchar.png';
import OrangeCoin from '../images/SilverChar.png';
import { message } from 'antd';

const images = [
  { name: 'Purple Skin', price: '500 Points', src: purpleCoin },
  { name: 'Default Skin', price: 'Free', src: OrangeCoin },
  { name: 'Guard Skin', price: '2200 Points', src: GuardCoin },
  { name: 'Battle Skin', price: '5000 Points', src: BattleCoin },
  // Add more images as needed
];

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ theme, open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
}));

const SkinsModal = ({ open, handleClose, userData, userSkins, userCurrentSkin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading1(false), 1000);
    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);

  const handleBuySkin = (event) => {
    const skinID = Number(event.currentTarget.id); // Ensure skinID is a number
    setIsLoading(true);

    axios.post(`${process.env.REACT_APP_API_URL}/api/buy-skin`, {
      userId: userData.id,
      skinID,
    })
    .then(response => {
      message.success('Skin purchased successfully!');
      handleClose();
    })
    .catch(error => {
      if (error.response?.data?.error === 'SkinID already exists') {
        axios.post(`${process.env.REACT_APP_API_URL}/api/change-skin`, {
          userId: userData.id,
          skinID,
        })
        .then(response => {
          message.success('Skin changed successfully');
          handleClose();
        })
        .catch(changeError => {
          message.error('Error changing skin');
          console.error('Error changing skin:', changeError);
        });
      } else {
        message.error('Insufficient balance');
        console.error('Error purchasing skin due to insufficient balance:', error);
      }
    })
    .finally(() => setIsLoading(false));
  };

  return (
    <AnimatedDiv open={open} style={{ height: '85vh', paddingBottom: '130px', padding: '25px', overflowY: 'scroll' }}>
      {isLoading1 ? (
        <div style={{ textAlign: 'center' }}>
           <CircularProgress
            sx={{
              color: "#A6FF00", // Spinner color
              mt: 27,
              width: "50px",
              height: "50px",
            }}
          />
        </div>
      ) : (
        <Grid container spacing={2} marginBottom={5}>
          {images.map((image, index) => (
            <Grid key={index} item xs={6}>
              <Paper
                sx={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  position: 'relative',
                  background: '#F4FDFF0A',
                }}
              >
                <img src={image.src} alt={`Product ${index + 1}`} style={{ width: '100%', borderRadius: '10px', height: '200px' }} />
                <div style={{ padding: '10px' }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white' }}>{image.name}</Typography>
                  <Typography variant="body2" sx={{ color: 'white' }}>{image.price}</Typography>
                </div>
                <Button
                  onClick={handleBuySkin}
                  id={index + 1}
                  variant="contained"
                  sx={{
                    backgroundColor: `${userCurrentSkin === index + 1 ? isLoading ? 'darkgray' : 'dodgerblue' : userSkins.includes(index + 1) ? 'gray' : '#A6FF00'}`,
                    width: '100%',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontFamily: 'avenir',
                    color: 'black',
                  }}
                >
                  {userCurrentSkin === index + 1 ? 'Selected' : userSkins.includes(index + 1) ? 'Select' : 'Buy'}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </AnimatedDiv>
  );
};

export default SkinsModal;
