import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  Fade,
  Typography,
  Grid,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import airdropMain from "../images/airdropMain.png";
import axios from "axios";
import { message } from "antd";

import totalpointsIcon from "../images/totalpointsIcon.png";

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled("div")(({ open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
}));

const BoostModal = ({ open, handleClose, userData }) => {
  const [points, setPoints] = useState(0);
  const [Gradename, setGradeName] = useState([]);
  const [boosts, setBoosts] = useState([]);
  // const [boostsNames, setBoostsNames] = useState([]);
  const [currentGrade, setCurrentGrade] = useState([]);
  const [UserBoost, setUserBoots] = useState();

  const [loading, setLoading] = useState(true);

  // fatch all user data and set user data in state
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/${userData.id}`
      );
      const data = response.data;
      setPoints(data.points);
      setUserBoots(data.boost);
      setCurrentGrade(data.grade);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // get all grads
  const levels = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/getGrades`
      );
      const data = response.data;
      setGradeName(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //get user current grade
  // const currentlevel = useCallback(async (userData, id) => {
  //     try {

  //         const response = await axios.get(
  //             `${process.env.REACT_APP_API_URL}/api/v1/user/${data.id}/get-grade`
  //         );
  //         const data = response.data;
  //         console.log("Data..====>",data);
  //         // setCurrentGrade({ name: data.grade, });
  //         console.log(data);
  //         setLoading(false);
  //     } catch (error) {
  //         console.error('Error fetching data:', error);
  //         setLoading(false);
  //     }
  // }, []);

  const fetchBoosts = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/${userData.id}/getBoosts`; // Include userId in the URL path
      console.log("Requesting URL in boosts :", apiUrl);

      const response = await axios.get(apiUrl);

      const data = response.data;

      if (data.success) {
        setBoosts(data.data);
      } else {
        console.error("Error fetching data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const getGradeBooster = useCallback(async (userData) => {
  //     try {
  //         const response = await axios.get(
  //             `${process.env.REACT_APP_API_URL}/api/v1/user/${userData.id}/get-grade`
  //         );
  //         const data = response.data.data;
  //         console.log("boostdata", data);

  //         if (data) {
  //             console.log("Data structure:", data);
  //             if (data.boosts) {
  //                 const boostsData = data.boosts.map(boost => ({
  //                     boostName: boost.name,
  //                     costShares: boost.costShares // Assuming costShares is directly available in boost object
  //                 }));
  //                 console.log("boostsData", boostsData);
  //                 setBoostsNames(boostsData); // Set boostsNames state with array of objects containing name and costShares
  //             } else {
  //                 console.warn("No boosts found in data:", data);
  //                 setBoostsNames([]); // Ensure boostsNames state is an empty array if no boosts found
  //             }
  //         } else {
  //             console.warn("Data is undefined:", data);
  //             setBoostsNames([]); // Ensure boostsNames state is an empty array if data is undefined
  //         }

  //         setLoading(false);
  //     } catch (error) {
  //         console.error('Error fetching data:', error);
  //         setLoading(false);
  //     }
  // }, []);

  const handleGradeClick = async (grade, id) => {
    try {
    
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/user/upgrade-grade`,
          {
            userId: userData.id,
            gradeName: grade.name,
          }
        );
        console.log("Upgrade successful:", response.data);
      message.success("Yor level upgraded successfully!");
      // Optionally, update the state/UI here to reflect the successful upgrade
    } catch (error) {
      message.error("already upgraded level");
      console.error("Error upgrading grade:", error);
    }
  };

  //update boosts
  const upgradeBoost = async (boost) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/buy-boost`,
        {
          userId: userData.id,
          boostName: boost.name,
        }
      );
      console.log("Upgrade successful:", response.data);
      message.success(`Your  boost upgraded successfully!`);
    } catch (error) {
      message.error("Already upgraded boost");
      console.error("Error upgrading grade:", error);
    }
  };

  useEffect(() => {
    fetchData();
    levels();
    fetchBoosts();
    // getGradeBooster()
    // currentlevel();
  }, [userData]);

  return (
    // <Modal
    //   open={open}
    //   onClose={handleClose}
    //   aria-labelledby="modal-title"
    //   aria-describedby="modal-description"
    //   closeAfterTransition
    //   BackdropProps={{
    //     timeout: 500,
    //   }}
    // >
      // <Fade in={open}>
      //   <AnimatedDiv
      //     open={open}
      //     style={{
      //       backgroundColor: "#1c1f24",
      //       backdropFilter: "blur(10px)",
      //       padding: "20px",
      //       borderRadius: "10px",
      //       maxWidth: "330px",
      //       height: "85vh",
      //       margin: "auto",
      //       marginTop: "4vh",
      //       overflowY: "auto",
      //     }}
      //   >
      <div style={{padding: '30px',height: '85vh',marginBottom:'10px',boxSizing: 'border-box',overflowY:'scroll'}}>
          <Grid container spacing={4} textAlign={"center"} marginBottom={"5px"}>
            <Box
              sx={{ textAlign: "center", width: "100%", ml: 2, mb: 4, mt: 5 }}
            >
              <Typography
                fontFamily={"avenir, sans-serif, serif"}
                fontWeight={700}
                color={"white"}
                fontSize={"1rem"}
                sx={{ mt: 4, mb: 2 }}
              >
                Your Balance
              </Typography>
              <img
                src={totalpointsIcon}
                alt="EarnCoin"
                style={{
                  verticalAlign: "middle",
                  marginLeft: "5px",
                  marginRight: "5px",
                  marginTop: "2px",
                }}
              />
              <Typography
                fontFamily={"avenir, sans-serif, serif"}
                fontWeight={700}
                color={"white"}
                fontSize={"2rem"}
                display={"inline"}
              >
                {points}
              </Typography>
            </Box>
          </Grid>
          <Grid container spacing={4} textAlign={"center"} sx={{ mb: 2 }}>
            <Box sx={{ alignItems: "center", width: "100%", ml: 2 }}>
              <Box
                style={{
                  textAlign: "left",
                  width: "100%",
                  margin: "5px",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  fontFamily={"avenir, sans-serif, serif"}
                  fontWeight={700}
                  color={"white"}
                  fontSize={"1rem"}
                  textAlign={"center"}
                >
                  Your Current Level :{" "}
                  <span style={{ color: "#A6FF00" }}>{currentGrade}</span>
                </Typography>

                <Typography
                  fontFamily={"avenir, sans-serif, serif"}
                  fontWeight={700}
                  color={"white"}
                  fontSize={"1rem"}
                  textAlign={"center"}
                >
                  Your Current Boost :{" "}
                  <span style={{ color: "#A6FF00" }}>{UserBoost}</span>
                </Typography>
              </Box>

              <Box
                style={{
                  textAlign: "left",
                  width: "95%",
                  margin: "5px",
                  padding: "10px",
                  borderRadius: "10px",
                  }}
              >
                <Typography
                  fontFamily={"avenir, sans-serif, serif"}
                  fontWeight={700}
                  color={"#A6FF00"}
                  fontSize={"1.5rem"}
                >
                  Grade/Level
                </Typography>
                {Gradename.map((grade, index) => (
                  <Box
                    key={index}
                    bgcolor={"#F4FDFF0A"}
                    style={{
                      textAlign: "left",
                      width: "95%",
                      margin: "5px",
                      padding: "10px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleGradeClick(grade)}
                  >
                    <Typography
                      fontFamily={"avenir, sans-serif, serif"}
                      fontWeight={700}
                      color={"white"}
                      fontSize={"1rem"}
                    >
                      {grade.name}
                    </Typography>
                    <Typography
                      fontFamily={"avenir, sans-serif, serif"}
                      fontWeight={700}
                      color={"black"}
                      fontSize={"1rem"}
                      borderRadius={"10px"}
                      backgroundColor={"#A6FF00"}
                      style={{padding:"8px"}}
                    >
                      Level {grade.currentGrade}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid container spacing={4} textAlign={"center"}>
            {/* <Box sx={{ alignItems: 'center', width: '100%', ml: 2 }}>

                            <Box style={{ textAlign: 'left', width: '100%', margin: '5px', padding: '10px', borderRadius: '10px' }}>
                             

                            </Box>

                        </Box> */}
            <Box sx={{ alignItems: "center", width: "100%", ml: 2 }}>
              <Box
                style={{
                  textAlign: "left",
                  width: "95%",
                  margin: "5px",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  fontFamily={"avenir, sans-serif, serif"}
                  fontWeight={700}
                  color={"#A6FF00"}
                  fontSize={"1.5rem"}
                >
                  Boosters
                </Typography>
                {boosts.map((boost, index) => (
                  <Box
                    key={index}
                    bgcolor={"#F4FDFF0A"}
                    style={{
                      textAlign: "left",
                      width: "95%",
                      margin: "5px",
                      padding: "10px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => upgradeBoost(boost)}
                  >
                    <Typography
                      fontFamily={"avenir, sans-serif, serif"}
                      fontWeight={700}
                      color={"white"}
                      fontSize={"1rem"}
                    >
                      {boost.name}
                    </Typography>
                    <Typography
                      fontFamily={"avenir, sans-serif, serif"}
                      fontWeight={700}
                      color={"black"}
                      fontSize={"1rem"}
                      borderRadius={"10px"}
                      backgroundColor={"#A6FF00"}
                      style={{padding:"8px"}}
                    >
                      {boost.costShares > 0
                        ? `${boost.costShares} Shares`
                        : `${boost.costBNB} BNB`}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* <Box
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <CloseIcon onClick={handleClose} />
          </Box> */}
         {/* </AnimatedDiv> */}
       {/* </Fade> */}
     {/* </Modal> */}
    </div>
  );
};

export default BoostModal;
