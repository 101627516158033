import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  Avatar,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { message } from "antd";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  LinearProgress,
  CircularProgress

} from "@mui/material";
import SkinsModal from "./Skins";
import FriendsModal from "./Freinds";
import LeaderboardModal from "./Leaderboard";
import { keyframes } from "@emotion/react";
import MyProgress from "./Progress";
import axios from "axios";
import leafRight from "../images/leaf-right.png";
import totalpointsIcon from "../images/totalpointsIcon.png";
import leaderBORD from "../images/leaderBORD.png";
import friends from "../images/friends.png";
import skins from "../images/skins.png";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useNavigate } from 'react-router-dom';

// import images of skins
import OrangeCoin from "../images/SilverChar.png";
import defaultCoin from "../images/basicChar.png";
import GuardCoin from "../images/goldchar.png";
import BattleCoin from "../images/Diamand.png";
import TaskModal from "./Task";
import AirdropModal from "./Airdrop";
import PaidIcon from "@mui/icons-material/Paid";
import boost from "../images/boost.png";
import BoostModal from "./Boost";

const isDesktop = window.innerWidth > 1000;
const theme = createTheme();

// Styled components for the gold buttons
const GoldButton = styled(Button)({
  backgroundColor: "transparent",
  borderRadius: 15,
  width: "20vw",
  margin: "10px",
  padding: window.innerHeight < 740 ? "5px" : "10px",
  fontFamily: "avenir",
  fontSize: "19px",
  textTransform: "Capitalize",
  fontWeight: 800,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "white",
  },
});

const CoinLogo = styled(Box)({
  width: "35vw",
  marginBottom: "15px",
  // filter: 'hue-rotate(12deg) drop-shadow(0px 0px 25px #0152AC)',
  [theme.breakpoints.down("md")]: {
    width: window.innerHeight < 740 ? "67vw" : "75vw",
    marginBottom: window.innerHeight < 740 ? "10px" : "35px",
  },
});

// keyframes for animation
const expand = keyframes`
   from, to { width: ${isDesktop ? "33vw" : "73vw"}; }
   20% { width: ${isDesktop ? "28.5vw" : "68vw"}; }
   50% { width: ${isDesktop ? "30vw" : "70vw"}; }
`;

const fontSizeAnim = keyframes`
   from, to { font-size: ${isDesktop ? "22px" : "26px"}; }
   50% { font-size: ${isDesktop ? "22px" : "26px"}; }
`;

const floatUpAndFadeOut = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
`;

export default function CoinApp(props) {
  const {
    userData,
    profileUrl,
    telApp,
    userId,
    pointCount,
    setPointCount,
    miningInfo,
    setMiningInfo,
    points,
  } = props;
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openSkins, setOpenSkins] = useState(false);
  const [openFriends, setOpenFriends] = useState(false);
  const [openLeaderboard, setOpenLeaderboard] = useState(false);
  const [openTaskList, setOpenTaskList] = useState(false);
  const [opanAirdrop, setOpenAirdrop] = useState(false);
  const [expandAnimation, setExpandAnimation] = useState("");
  const [fontSizeAnimation, setFontSizeAnimation] = useState("");
  const [textPoints, setTextPoints] = useState([]);
  const [userAddress, setUserAddress] = useState("");
  const [userSkins, setUserSkins] = useState([]);
  const [userCurrentSkinID, setUserCurrentSkinID] = useState();
  const [userCurrentSkinImage, setUserCurrentSkinImage] = useState(0);
  const [userCurrentReferrals, setUserCurrentReferrals] = useState(0);
  const [userReferralsInfo, setUserReferralsInfo] = useState([]);
  const [userCurrentRank, setUserCurrentRank] = useState(null);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [userMiningInfo, setUserMiningInfo] = useState({
    status: "idle",
    perClick: 1,
    pointCount: 0,
    limit: 0,
    max: 0,
  });

  const [task, setTask] = useState([]);
  const [audio] = useState(
    new Audio("https://assets.mixkit.co/active_storage/sfx/216/216.wav")
  );

  const [showButton, setShowButton] = useState(true);
  const [opanBoost, setOpenBoost] = useState("");
  const [gradeData, setGradeData] = useState({ name: "", currentGrade: 0 });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setShowButton(false);
  };

  const handleGetTapUserData = useCallback(async () => {
    try {
      if (userData.id) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/user/${userData.id}/getUserTapData`
        );
        // console.log("Response User Data ", response.data);
        setUserInfo(response.data.data); // Update state with fetched data
        console.log('Fetched points from backend:', response.data.points);
        setUserMiningInfo({
          status: "Active",
          pointCount: response.data.data.user.points,
          perClick: response.data.data.tapPoints,
          limit: response.data.data.boosts[0].denominator,
          max: response.data.data.boosts[0].numerator,
        });
      }
    } catch (error) {
      console.error("Error fetching getUserTapData:", error);
    }
  }, [userData.id]);

  useEffect(() => {
    handleGetTapUserData(); // Call the function to fetch data when the component mounts
  }, [handleGetTapUserData]);


  useEffect(() => {
    // handleGetTapUserData();

    const interval = setInterval(async () => {
      setUserMiningInfo((prevMiningInfo) => {
        // Only increase limit if it's below the max
        if (userData.id)
          axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userData.id}`);
        if (prevMiningInfo.limit < prevMiningInfo.max) {
          return { ...prevMiningInfo, limit: prevMiningInfo.limit + 1 };
        } else {
          // Otherwise, keep the previous state unchanged
          clearInterval(interval); // If limit reached max, clear the interval to stop it
          return prevMiningInfo;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [userMiningInfo.limit]);

  useEffect(() => {
    const req = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/tap`; // Updated URL with /v1
      try {
        const response = await axios.post(apiUrl, {
          userId: userData.id,
        });
        handleGetTapUserData(); // Call the function to fetch data when the component mounts

      } catch (error) {
        console.error("Error updating score:", error);
        if (error.response && error.response.status === 404) {
          console.error("Endpoint not found (404)");
        }
        // Additional code to handle the error...
      }
    };

    if (userData.id) {
      req();
    }
  }, [userData.id]);

  useEffect(() => {
    const req = async () => {
      try {
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/${userData.id}`
        );
        const userCurrentSkinID = userResponse.data.skinID;
        setUserSkins(userResponse.data.skins);
        setUserCurrentSkinID(userCurrentSkinID);
        setUserCurrentReferrals(userResponse.data.referrals);
        setUserReferralsInfo(userResponse.data.referralsInfo);
        setLoading(false);

        // set user images
        switch (userCurrentSkinID) {
          case 1:
            setUserCurrentSkinImage(defaultCoin);
            break;
          case 2:
            setUserCurrentSkinImage(OrangeCoin);
            break;
          case 3:
            setUserCurrentSkinImage(GuardCoin);
            break;
          case 4:
            setUserCurrentSkinImage(BattleCoin);
            break;
          default:
            setUserCurrentSkinImage(defaultCoin);
        }
      } catch (error) {
        console.error("Error getting user data:", error);
      }

      try {
        const rankResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/${userData.id}/get-rank`
        );
        setUserCurrentRank(rankResponse.data.rank);
      } catch (error) {
        console.error("Error getting rank:", error);
      }

      try {
        const leaderboardResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/leaderboard`
        );
        setLeaderboardList(leaderboardResponse.data.users);
      } catch (error) {
        console.error("Error getting leaderboard:", error);
        // Additional code to handle the error...
      }
    };

    req();
  }, [userMiningInfo.pointCount, openSkins, openFriends, openTaskList]);

  const getGrade = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${userData.id}/get-grade`
      );
      const data = response.data;
      setGradeData({
        name: data.data.name,
        currentGrade: data.data.currentGrade,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  }, [userData.id]);

  useEffect(() => {
    getGrade();
  }, [getGrade]);

  const handleOpen = () => setOpenWithdraw(true);
  const handleClose = () => setOpenWithdraw(false);

  // Handle the change of the address input
  const handleAddressChange = (event) => setUserAddress(event.target.value);

  const handleCoinClick = (event) => {
    setShowButton(false); // removes tap to earn text
    // Check if the limit is loaded and valid
    if (!userMiningInfo || userMiningInfo.limit === undefined) {
      return; // Skip action if data isn't fully loaded
    }

    if (userMiningInfo.limit > 0) {
      // Calculate new limit and update state
      const newLimit = Math.max(userMiningInfo.limit - userMiningInfo.perClick, 0);

      setUserMiningInfo((prev) => ({
        ...prev,
        pointCount: prev.pointCount + prev.perClick,
        limit: newLimit,
        status: "mining",
        perClick: userInfo.tapPoints,
        max: userInfo.boosts[0].numerator,
      }));

      setValue((prevValue) => Math.min(prevValue + 1, 100));

      // Trigger animations
      setExpandAnimation(`${expand} 0.1s ease`);
      setFontSizeAnimation(`${fontSizeAnim} 0.1s ease`);

      // Handle audio playback carefully
      if (audio.paused) {
        audio.play().catch((error) => {
          console.warn("Audio playback failed:", error);
        });
      }

      // Capture click/touch coordinates
      const x = event.clientX || event.touches?.[0]?.clientX;
      const y = event.clientY || event.touches?.[0]?.clientY;

      // Add new point element
      setTextPoints((prevPoints) => [
        ...prevPoints,
        { x, y, id: Date.now() }, // Unique ID using timestamp
      ]);

      // Reset animations
      setTimeout(() => {
        setExpandAnimation("");
        setFontSizeAnimation("");
      }, 200);
    } else {
      // Show alert if limit is reached
      setMiningInfo((prev) => ({ ...prev, status: "stop" }));
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.showAlert(
          "Mining limit reached. Please try again later."
        );
      }
    }
  };



  // this function will show withdraw modal after user clicked on the button
  const handleWithdrawClick = () => {
    setOpenWithdraw(true);
  };

  const removePoint = (id) => {
    setTextPoints(textPoints.filter((point) => point.id !== id));
  };

  const getStyle = () => {
    const baseStyle = {
      position: "absolute",
      top: "70%",
      bottom: "190px",
      color: "aliceblue",
      animation: fontSizeAnimation,
      fontFamily: "avenir",
      fontSize: isDesktop ? "18px" : "13px",
      width: "92%", // Default width value
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "@media (min-width: 769px)": {
        left: "25vw", // Default left value for larger screens
      },

      // Media query for screens 768px or less
      "@media (max-width: 768px)": {
        width: "92vw", // Adjust width for smaller screens
        // Additional styles specific to smaller screens
      },
    };

    return baseStyle;
  };

  const getPointLeftPosition = (pointCount) => {
    const thresholds = [
      [999999999999999, "17vw"],
      [9999999999999, "21vw"],
      [999999999999, "23vw"],
      [9999999999, "27vw"],
      [999999999, "29vw"],
      [99999999, "32vw"],
      [9999999, "34vw"],
      [999999, "36vw"],
      [99999, "38vw"],
      [9999, "40vw"],
      [999, "42vw"],
      [99, "45vw"],
      [9, "46vw"],
    ];

    for (const [threshold, position] of thresholds) {
      if (pointCount > threshold) {
        return position;
      }
    }

    return "47.5vw";
  };

  const getCoinSkinShadow = (userCurrentSkinID) => {
    switch (userCurrentSkinID) {
      case 1:
        return "0px 0px 45px #A6FF00";
      case 2:
        return "0px 0px 45px #FAE088";
      case 3:
        return "0px 0px 45px #5c716c";
      case 4:
        return "0px 0px 45px skyblue";
      default:
        return "0px 0px 45px #0152AC";
    }
  };

  const PointContainer = styled("div")(({ isDesktop }) => ({
    display: "flex",
    alignItems: "center",
    fontWeight: 800,
    fontFamily: "avenir",
    position: "absolute",
    top: "20%",
    left: getPointLeftPosition(userMiningInfo.pointCount), // Assuming this function is defined elsewhere
    color: "aliceblue",
    animation: fontSizeAnimation, // Assuming this variable is defined elsewhere
    fontSize: isDesktop ? "23px" : "25px",
    zIndex: 1,
    justifyContent: "center",
    marginBottom: "10px",
    gap: "0px",
  }));

  const [value, setValue] = useState(0);

 
  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1,
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <CircularProgress
            sx={{
              color: "#A6FF00", // Spinner color
              mt: 3,
              width: "50px",
              height: "50px",
            }}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px",
              // background: "rgba(0,0,0,0.21)",
              color: "white",
              padding: "10px",
              backdropFilter: "blur(10px)",
              borderRadius: "20px",
              width: `${isDesktop ? "30vw" : "90vw"}`,
              height: `${isDesktop ? "6.5vw" : "10vh"}`,
            }}
          >
            <Typography
              variant="h5"
              component="p"
              sx={{
                fontWeight: "800",
                fontFamily: "Avenir",
                flexGrow: 1,
                textAlign: "center",
              }}
            >
              {userData.first_name}
            </Typography>
          </Box>

          <PointContainer style={{ marginLeft: "-14px", marginBottom: "20px" }}>
            <img src={totalpointsIcon} alt="" style={{ marginRight: "5px" }} />
            {userMiningInfo.pointCount}
          </PointContainer>

          <Typography
            component="p"
            sx={{
              fontWeight: "600",
              fontFamily: "avenir",
              position: "absolute",
              top: "12%",
              color: "aliceblue",
              fontSize: `${isDesktop ? "14px" : "16"}`,
              zIndex: 1,
            }}
          >
            <img
              src={leafRight}
              alt="leaf"
              style={{
                filter: "invert(1)",
                width: "40px",
                verticalAlign: "middle",
                transform: "scaleX(-1)",
              }}
            />
            Ranking :{" "}
            {userCurrentRank === null
              ? "Loading..."
              : userCurrentRank === 1
                ? "1st"
                : userCurrentRank === 2
                  ? "2nd"
                  : userCurrentRank === 3
                    ? "3rd"
                    : `${userCurrentRank}th`}
            <img
              src={leafRight}
              alt="leaf"
              style={{
                filter: "invert(1)",
                width: "40px",
                verticalAlign: "middle",
              }}
            />
          </Typography>

          <div style={{ position: "relative", bottom: "22px", marginTop: "100px" }}>
            <CoinLogo
              component="img"
              width={350}
              height={350}
              src={userCurrentSkinImage || OrangeCoin}
              alt="Coin Logo"
              onClick={handleCoinClick}
              onTouchStart={handleCoinClick}

              sx={{
                animation: expandAnimation,
                "&:hover": { cursor: "pointer" },
                filter: `drop-shadow(${getCoinSkinShadow(userCurrentSkinID)})`,

              }}
            />
            {showButton && (
              <div
                onClick={handleButtonClick}
                variant="contained"
                color="primary"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                Tap to Earn
              </div>
            )}
          </div>

          {textPoints.map((point) => (
            <Box
              key={point.id}
              sx={{
                position: "absolute",
                left: point.x - 10,
                top: point.y - 20,
                animation: `${floatUpAndFadeOut} 1s ease forwards`, // forwards keeps the end state after animation completes
                fontSize: `${isDesktop ? "40px" : "35px"}`,
                fontFamily: "avenir",
                color: "white",
              }}
              onAnimationEnd={() => removePoint(point.id)} // remove element after animation
            >
              +{userMiningInfo.perClick}
            </Box>
          ))}

          <p style={getStyle()} className="mining-info-container">
            <img
              style={{ verticalAlign: "bottom", marginRight: "5px" }}
              width="28"
              height="30"
              src="https://img.icons8.com/fluency/48/flash-on.png"
              alt="flash-on"
            />
            <span
              style={{
                fontSize: `${isDesktop ? "25px" : "20px"}`,
                marginRight: "auto",
              }}
            >
              {userMiningInfo.limit} / {userMiningInfo.max}
            </span>
            <Box
              sx={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
              onClick={() => navigate('/boost')}
            >
              <img src={boost} width={48} height={48} alt="boost" />
              <Typography sx={{ marginLeft: "10px", mt: 2, fontFamily: "avenir" }}>
                Boost
              </Typography>
            </Box>
          </p>

          <Box></Box>

          <Box sx={{ position: "fixed", width: { xs: "100vw", md: "50vw" }, bottom: "8vh" }}>
            <Box sx={{ padding: "16px" }}>
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "yellow",
                    }}
                  >
                    <Typography variant="h5" color={"white"}>
                      {gradeData.name}
                    </Typography>
                    <Typography variant="h5" color="white">
                      Level {gradeData.currentGrade}/5
                    </Typography>
                  </Box>
                  <Box sx={{ position: "relative", margin: "16px 0" }}>
                    {/* <LinearProgress
                  value={gradeData.currentGrade}
                  variant="determinate"
                  sx={{
                    height: "10px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(211, 211, 211, 0.5)",
                    border: "1px solid black",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#A6FF00",
                    },
                  }}
                /> */}
                     <MyProgress
                      level={gradeData.currentGrade}
                      value={userMiningInfo.pointCount} // Replace with dynamic value if needed
                    />

                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
