import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Define the levels and their ranges
const levels = [
  { level: 1, min: 0, max: 10000},
  { level: 2, min: 10000, max: 20000 },
  { level: 3, min: 20000, max: 30000 },
  { level: 4, min: 30000, max: 40000 },
  { level: 5, min: 40000, max: 50000 },
];

// Define colors for each level
const levelColors = [
  '#00BFFF', // Level 1 - Light Blue
  '#32CD32', // Level 2 - Lime Green
  '#FFD700', // Level 3 - Gold
  '#FFA07A', // Level 4 - Light Salmon (Light Orange)
  '#FF4500', // Level 5 - Orange Red (for a stronger color contrast)
];

const BorderLinearProgress = styled(LinearProgress)(({ theme, levelColor }) => ({
  height: 10,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: levelColor,
  },
}));

const MyProgress = ({ level, value }) => {
  // Ensure the provided level is valid
  if (level < 1 || level > levels.length) return null;

  // Find the current level and the previous level (for calculating progress within the current level)
  const currentLevel = levels.find(lvl => lvl.level === level);
  const previousLevel = levels.find(lvl => lvl.level === level - 1) || { max: 0 };

  if (!currentLevel) return null;

  // Determine the color for the current level
  const levelColor = levelColors[level - 1];

  // Calculate the progress within the current level
  const levelRange = currentLevel.max - previousLevel.max;
  const levelProgress = Math.max(0, Math.min(value - previousLevel.max, levelRange));
  const progressPercentage = (levelProgress / levelRange) * 100;

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" ml={2}>
        <BorderLinearProgress variant="determinate" value={progressPercentage} levelColor={levelColor} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          progressPercentage,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default MyProgress;
